

export const useBusiness = () => {
    return {
        getLogoUrl: (item) => {
            const backendUrl = useBackendUrl()
            if(item?.attributes?.logo?.data?.attributes?.formats?.medium?.url) return backendUrl + item?.attributes?.logo?.data?.attributes?.formats?.medium?.url
            if(item?.attributes?.logo?.data?.attributes?.formats?.thumbnail?.url) return backendUrl + item?.attributes?.logo?.data?.attributes?.formats?.thumbnail?.url
            if(item?.attributes?.logo?.data?.attributes?.url) return backendUrl + item?.attributes?.logo?.data?.attributes?.url
        },
    }
}

export const useCurrentBusiness = () => {
    return useBusinessById('current')
}

export const useRefreshBusinessStatus = (id) => {
    refreshNuxtData('business-'+id+'-status')
}
export const useBusinessStatus = (id, options) => {
    return useAsyncData(
        'business-'+id+'-status'+(options?.key ? '-'+options.key : ''),
        () => useBusinessValidation(id, options),
        {
            initialCache: false,
        }
    )
}

export const useBusinessById = (id, customKey) => {
    const { findOne } = useStrapi4()
    return useLazyAsyncData(
        'business-'+id+(customKey ?? ''),
        () => findOne('businesses', id)
    )
}
export const useBusinessMarketingById = (id) => {
    const { find } = useStrapi4()
    return useLazyAsyncData(
        'business-marketing-'+id,
        () => find('business-marketings', {
            filters: {
                business: { id: { $eq: id } },
            },
            populate: [ 'questions', 'address', 'stores', 'questions.question', 'stores.address', 'stores.openingTimes', 'offers', 'offers.type', 'logo', 'images'],
        })
    )
}
export const useBusinessContractsById = (id) => {
    const { find } = useStrapi4()
    return useLazyAsyncData(
        'contracts-business-'+id,
        () => find('contracts', {
            filters: {
                $and: [
                    { business: { id: { $eq: id } } },
                    { hide: { $ne: true } }
                ]
            },
            populate: ['organisation', 'organisation.logo', 'organisation.parent', 'organisation.parent.logo'],
        })
    )
}