
export const useBusinessValidation = async(id, options) => {
    const checks = [
        { code: 'no-address', to: `/business/${id}/core`, needs: ['businesses'], passCheck: (data) => data['businesses'].data?.attributes?.contactAddress },
        /**
         * Marketing is now optional:
        { code: 'no-marketing', to: `/business/${id}/marketing`, needs: ['business-marketings'], passCheck: (data) => data['business-marketings'].data?.length },
        { code: 'no-marketing-address', to: `/business/${id}/marketing`, needs: ['business-marketings'], passCheck: (data) => data['business-marketings'].data?.[0].attributes?.address },
        { code: 'no-marketing-questions', to: `/business/${id}/marketing`, needs: ['business-marketings'], passCheck: (data) => data['business-marketings'].data?.[0].attributes?.questions?.length },
        { code: 'no-marketing-images', to: `/business/${id}/marketing`, needs: ['business-marketings'], passCheck: (data) => data['business-marketings'].data?.[0].attributes?.images?.data?.length},
         */
        { code: 'no-contract', to: `/business/${id}/contracts`, needs: ['contracts'], passCheck: (data) => data['contracts'].data?.length },
        { code: 'no-active-contract', to: `/business/${id}/contracts`, needs: ['contracts'], passCheck: (data) => data['contracts'].data?.filter(c => c.attributes?.archived == false).length },
        { code: 'no-contract-signed', to: `/business/${id}/contracts`, needs: ['contracts'], passCheck: (data) => data['contracts'].data?.filter(c => c.attributes?.archived == false && c.attributes?.acceptedByProducer == true).length },
        { informal: true, code: 'no-contract-signed-by-organisation', to: `/business/${id}/contracts`, needs: ['contracts'], passCheck: (data) => data['contracts'].data?.filter(c => c.attributes?.archived == false && c.attributes?.acceptedByOrganisation == true).length },
        { informal: true, code: 'no-contract-signed-by-organisation', to: `/business/${id}/contracts`, needs: ['contracts'], passCheck: (data) => data['contracts'].data?.filter(c => c.attributes?.archived == false && c.attributes?.acceptedByParentOrganisation == true).length },
    ]
    const getData = async(entity, id, currentData) => {
        const { find, findOne } = useStrapi4()
        if( entity === 'businesses' )
            return await findOne(entity, id)
            
        let populate = ['*'];
        if( entity === 'business-marketings' )
            populate = ['questions', 'address', 'stores', 'images']
        if( entity == 'contracts' )
            populate = ['organisation']
            
        try {
            return await find(entity, {
                filters: {
                    business: { id: { $eq: currentData?.['businesses']?.data?.id ?? id } },
                },
                populate: populate,
            })
        } catch(e){
            return false
        }
    }
    if(options?.codes){
        return await useRunStatusCheck( checks.filter(c => options.codes.includes(c.code)), id, getData )
    }
    return await useRunStatusCheck( checks, id, getData )
}